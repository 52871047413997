<template>
  <div class="bglogin">
    <div class="bg-cover">
      <div class="loginForm">
        <div class="text-center">
          <img class="logo" src="./../../assets/logo.mini.png" alt/>
          <h3 class="login-desc" style="font-size: 26px">
            <!--            Manufacturing Execution System-->
            生产协同管理系统
          </h3>
        </div>
		<!-- 17354365017/lyq123456 -->
        <el-form
            :model="loginForm"
            ref="loginForm"
            class="demo-dynamic"
            label-position="left"
            label-width="0px"
        >
          <el-form-item
              prop="userName"
              label
              :rules="[
              { required: true, message: '请输入用户名', trigger: 'blur' },
            ]"
          >
            <dsn-input
                size="medium"
                v-model="loginForm.userName"
                placeholder="请输入用户名"
            ></dsn-input>
            <span class="left">用户名</span>
          </el-form-item>

          <el-form-item
              prop="password"
              label
              :rules="[
              { required: true, message: '请输入密码', trigger: 'blur' },
            ]"
          >
            <dsn-input
                size="medium"
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
                @keyup.enter.native="submitForm('loginForm')"
            ></dsn-input>
            <span class="left">密&nbsp;&nbsp;&nbsp;码</span>
          </el-form-item>
          <!--          <el-form-item-->
          <!--              prop="captcha"-->
          <!--              label-->
          <!--              :rules="[-->
          <!--              { required: true, message: '请输入验证码', trigger: 'blur' },-->
          <!--            ]"-->
          <!--          >-->
          <!--            <dsn-input-->
          <!--                size="medium"-->
          <!--                v-model="loginForm.captcha"-->
          <!--                placeholder="请输入验证码"-->
          <!--            ></dsn-input>-->
          <!--            <span class="left">验证码</span>-->

          <!--            <el-image-->
          <!--                v-if="validationImage != null"-->
          <!--                @click="handleGetLoginCheckKey"-->
          <!--                style="width: 105px; height: 35px"-->
          <!--                :src="validationImage"-->
          <!--            ></el-image>-->
          <!--          </el-form-item>-->

          <!-- <el-form-item label>
          <dsn-select size="medium" v-model="loginForm.systemKey" placeholder="请选择">
            <el-option label="SYS" value="SYS"></el-option>
            <el-option label="MES" value="MES"></el-option>
            <el-option label="SPS" value="SPS"></el-option>

          </dsn-select>
          <span class="left">系统</span>
          </el-form-item>-->
          <dsn-button
              class="btn-login"
              type="primary"
              @click="submitForm('loginForm')"
          >登录
          </dsn-button
          >
        </el-form>
      </div>
    </div>

    <el-dialog
        title="选择"
        class="dialog-login"
        width="500px"
        :visible.sync="dialog"
        v-el-drag-dialog
    >
      <el-form
          :model="loginForm"
          ref="login"
          class="dialog-login-form"
          label-position="left"
          label-width="0"
      >
        <el-form-item
            prop="systemKey"
            :rules="[{ required: true, message: '请选择系统', trigger: 'blur' }]"
        >
          <dsn-select
              size="medium"
              placeholder="请选择系统"
              v-model="loginForm.systemKey"
              @change="handelSystemChange"
          >
            <el-option
                v-for="(item, index) in loginConfigList"
                :key="index"
                :label="item.systemKey"
                :value="item.systemKey"
            ></el-option>
          </dsn-select>
          <span class="left">
            <i class="icon-xitong iconfont"></i>
          </span>
        </el-form-item>
        <el-form-item
            v-if="code == 202 && loginForm.systemKey !== 'SPS'"
            prop="tenantSiteCode"
            :rules="[{ required: true, message: '请选择工厂', trigger: 'blur' }]"
        >
          <dsn-select
              size="medium"
              v-model="loginForm.tenantSiteCode"
              placeholder="请选择工厂"
          >
            <el-option
                :key="item.siteCode"
                :label="item.siteName"
                :value="item.tenantSiteCode"
                v-for="item in siteList"
            ></el-option>
          </dsn-select>
          <span class="left">
            <i class="icon-icon-test iconfont"></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="dialog = false">取消</dsn-button>
        <dsn-button type="primary" @click="handleConfirm">确定</dsn-button>
      </span>
    </el-dialog>
    <!-- 底部版权信息 -->
     <div class="footer">copyright©2015-2023 深圳市德艺科技实业发展有限公司 All right reserved 粤ICP备19000310号 公安备案号:</div>
  </div>
</template>
<script>
import {getLoginCheckKey, getSystemList,} from "@/api/login.api.js";
import {setToken, toHome} from "@/until/action";
import {efmsLogin} from "@/api/efms/common/user.login.api";

export default {
  name: "login",
  data() {
    return {
      loginForm: {
        userName: "",
        password: "",
        usbKey: "abc",
        systemKey: "",
        tenantSiteCode: "",
        tenantCode: "",
        username: "",
        checkKey: "",
        captcha: "",
      },
      validationImage: null,
      loginForm2: {
        username: "",
        password: "",
        checkKey: "",
        captcha: "",
      },
      list: [],
      dialog: false,
      loginConfigList: [],
      // systemKeyList: [], // 系统
      code: 200,
      siteList: [], //系统对应工厂
    };
  },
  created() {
    const tenantCode = this.$route.query.tenantCode || "";
    this.loginForm.tenantCode = tenantCode;
    // this.handleGetSystemId();
    this.handleGetLoginCheckKey();
    document.title = "登录";
  },
  methods: {
    // 确定登录
    handleConfirm() {
      this.$refs["login"].validate((valid) => {
        console.log(this);
        if (valid) {
          // if (this.loginForm.systemKey === "SPS") {
          //   console.log(this.loginConfigList[0].tenantSiteCode)
          //   this.loginForm.tenantSiteCode = this.loginConfigList[0].tenantSiteCode;
          // }
          this.handleLoginConfirm(this.loginForm);
        }
      });
    },
    handleGetSystemId() {
      getSystemList().then((data) => {
        const res = data.data;
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    handelSystemChange(system) {
      if (system === "") {
        this.loginForm.tenantSiteCode = "";
        return;
      }
      this.loginConfigList.forEach((item) => {
        if (item.systemKey === system) {
          if (system === "SPS") {
            this.loginForm.tenantSiteCode = item.tenantSiteCode;
          } else {
            this.siteList = item.siteList;
          }
        }
      });
    },
    // handelSiteChange(v) {
    //   if (v == "") {
    //     this.systemKeyList = [];
    //     this.loginForm.systemKey = "";
    //     return;
    //   }
    //   const current = this.loginConfigList.find((item) => {
    //     return item.tenantSiteCode == v;
    //   });
    //   this.systemKeyList = current.systemKeyList;
    // },
    handleGetLoginCheckKey() {
      // let ckey = new Date().getTime();
      // getLoginCheckKey({
      //   key: ckey,
      // }).then((res) => {
      //   console.info(res);
      //   this.loginForm.checkKey = ckey;
      //   this.validationImage = res.data.result;
      //   this.$forceUpdate();
      // });
    },
    handleGetLoginConfig() {
      let postData = {}
      postData['LoginName'] = this.loginForm.userName;
      postData['Password'] = this.loginForm.password;
      efmsLogin(postData).then(res => {
        const {status, data} = res;
        if (status === 200) {
          if (data != null && data.data != null && data.resultCode === 0) {
            setToken(data.data.token);
            localStorage.setItem("userProfile", JSON.stringify(data.data))
            localStorage.setItem("USER_PERMISSIONS", JSON.stringify(data.data.permissionCodes))
			// console.log(data);
            toHome(this.loginForm.systemKey);
          } else {
            this.$message.error("登录失败 用户不存在或者密码错误")
          }
        }
      })
      // loginConfigInfoHttpV2({
      //   userName: this.loginForm.userName,
      //   username: this.loginForm.userName,
      //   password: this.loginForm.password,
      //   captcha: this.loginForm.captcha,
      //   checkKey: this.loginForm.checkKey,
      // }).then((data) => {
      //   // const res = data.data;
      //   // this.code = res.code;
      //   const res = data.data.result;
      //   this.code = data.data.code;
      //   if (this.code == 200) {
      //     // this.loginForm.tenantSiteCode =res.data[0].siteList[0].tenantSiteCode;
      //     this.loginForm.tenantSiteCode = "";
      //     // this.loginForm.systemKey = res.data[0].systemKey;
      //     this.loginForm.systemKey = "TEST";
      //
      //     this.$cookies.set("mcs.sessionId", data.data.result.token, {
      //       expires: "8h",
      //     });
      //
      //     this.$cookies.set("Authorization", data.data.result.token, {
      //       expires: "8h",
      //     });
      //     // setToken(data.id);
      //     setToken(data.data.result.token);
      //     toHome(this.loginForm.systemKey);
      //     // this.handleLoginConfirm(this.loginForm);
      //   } else if (this.code == 201) {
      //     // this.dialog = true;
      //     this.loginConfigList = res.data;
      //     this.loginForm.tenantSiteCode = res.data[0].tenantSiteCode;
      //   } else if (this.code == 202) {
      //     // this.dialog = true;
      //     this.loginConfigList = res.data;
      //
      //     for (var i = 0; i < res.data.length; i++) {
      //       if (res.data[i].systemKey == "MES") {
      //         this.loginForm.tenantSiteCode =
      //             res.data[i].siteList[0].tenantSiteCode;
      //         this.loginForm.systemKey = res.data[i].systemKey;
      //         this.handleLoginConfirm(this.loginForm);
      //         return;
      //       } else {
      //         this.$message.error(this.$t("message.loginerr"));
      //         return;
      //       }
      //     }
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: data.data.message,
      //     });
      //   }
      // });
    },
    handleLoginConfirm() {
      // login2(this.loginForm).then((res) => {
      //   const result = res.data;
      //   console.info(result);
      //   const data = result.data;
      //   console.info(data);
      //
      //   if (result.code == 200) {
      //     // this.dialog = false;
      //     // this.$cookies.set("mcs.sessionId", data.idid, { expires: "8h" });
      //     this.$cookies.set("mcs.sessionId", data.result.token, {
      //       expires: "8h",
      //     });
      //     // setToken(data.id);
      //     setToken(data.result.token);
      //     toHome(this.loginForm.systemKey);
      //     // this.$router.push("/welcome?systemId=" + this.loginForm.systemKey);
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: res.message,
      //     });
      //   }
      //
      //   // this.$store.state.userinfo.userinfo
      // });
      // this.$cookies.set("mcs.sessionId", "", {
      //   expires: "8h",
      // });
      //
      // localStorage.setItem("mcs.sessionId", "123");
      // // setToken(data.id);
      // setToken("123");
      // toHome(this.loginForm.systemKey);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleGetLoginConfig();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // }
  },
};
</script>

<style lang="scss">
.bglogin {
  // background: url("~@/assets/bg_login.svg");//白色
  background: url("~@/assets/newbg_login.png"); //白色
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  height: 100%;
  width: 100%;
  background-size: cover;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg-cover {
    // background: url("~@/assets/bg.png");//黑色
    background: url("~@/assets/newbg.png"); //黑色
    height: 100%;
    width: 100%;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer {
    padding: 15px;
    color: white;
  }
}

.loginForm {
  width: 380px;
  background: #fff;
  padding: 0px 50px 35px 50px;
  margin: 0 auto;
  border: 1px solid #eee;
  border-radius: 20px;

  .logo {
    width: 128px;
    height: 110px;
  }

  .login-desc {
    font-size: 22px;
    color: #282828;
    font-weight: normal;
    margin-bottom: 22px;
  }

  .el-form-item {
    position: relative;
    margin-bottom: 22px;

    .left {
      position: absolute;
      left: 16px;
      line-height: 50px;
      font-size: 16px;
      color: #676a74;
    }

    .dsn-input,
    .dsn-select {
      width: 100%;

      input {
        font-size: 16px;
        padding-left: 80px;
        height: 50px;
      }
    }
  }

  .dsn-select {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }

  .btn-login {
    margin-top: 8px;

    width: 100%;
    height: 50px;
    font-size: 16px;
  }
}

.dialog-login {
  .el-dialog__body {
    height: 150px;
  }
}

.dialog-login-form {
  .el-form-item {
    position: relative;
    margin-bottom: 22px;

    .left {
      position: absolute;
      left: 16px;
      line-height: 50px;
      font-size: 16px;
      color: #676a74;
    }

    .dsn-input,
    .dsn-select {
      width: 100%;

      input {
        font-size: 16px;
        padding-left: 58px;
        height: 50px;
      }
    }
  }
}
</style>
