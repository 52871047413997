import request from "@/service/";


export const efmsLogin = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/user/sapLogin`,
        data
    );
};


export const efmsLogout = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/user/logout`,
        data
    );
}
